.link{
    text-decoration: none;
    color: white;
    font-size: 20px;
    margin-left: 20px;
  }
  .link:hover {
    color: black;
  }
  .master-header{
    margin: 0;
    justify-content: center;
  }
  .text{
    width: 40%;
    justify-content: center;
    display:flex;
    float: left ;   
    margin: 8% 0px 0px 5%;
    text-align: center;
    overflow: hidden;


    font-size: 55px;
    font-weight: 600;
    margin-bottom: 14px;
    line-height: 55px;
    text-transform: capitalize;
    margin-bottom: 40px;
  }
  .img{
    width: 50%;
    display: flex;
    float: right;
    margin: 2% 10px 0px 0px;
  }